import React, {useState} from 'react';
import GumroadFollowForm from './GumroadFollowForm';
import styled from 'styled-components';
import Menu from './Menu';
import Footer from './Footer';
import Navbar from './Navbar';
import img from '../images/JED_rita_habib2.jpg'
import DesktopMenu from './DesktopMenu';
import gif from '../images/gif3.gif'



const About = () => {


    const [openSections, setOpenSections] = useState({});

  const toggleSection = (section) => {
    setOpenSections((prevSections) => ({
      ...prevSections,
      [section]: !prevSections[section],
    }));
  };

    return (
        <>
<Navbar/>
<DeskWrap>
<DesktopMenu/>
<Gif src= {gif}/>

<Wrapper>

    <h1>Spectral Center</h1>
    <SpectraDef>
       <h2>spec‧tral /ˈspektrəl/ <em>adjective</em></h2>
    <ol>
        <li><strong>literary</strong> relating to or like a spectre</li>
        <li><strong>technical</strong> relating to or made by a spectrum</li>
    </ol>
    
    <h2>cen‧ter /ˈsentə $ -ər/ <em>noun</em></h2>
    <ol>
        <li><strong>middle</strong> [countable usually singular] the middle of a space, area, or object, especially the exact middle</li>
        <li><strong>building</strong> [countable] a building which is used for a particular purpose or activity</li>
        <li><strong>place of activity</strong> [countable] a place where there is a lot of a particular type of business, activity etc</li>
    </ol>
    
    <h2><em>verb</em> [transitive]</h2>
    <ol><p> move something to a position at the center of something else</p></ol>

    </SpectraDef>
<Body>

<H1 onClick={() => toggleSection('about')}>About Julia E. Dyck</H1>
<Drop isOpen={openSections.about}>
<Img src= {img}/>

    <p>Julia E. Dyck is a hypnotherapist and artist with roots in Treaty One Territory/Winnipeg, now based between Brussels and Montreal/Tiohtià:ke.</p>
    
    <p>Julia’s journey with hypnosis began nearly a decade ago when she sought help to quit smoking. Referred by a bandmate to Andrea Young, Julia skeptically made an appointment that would ultimately transform her life. Through hypnosis, she not only quit smoking but also stopped drinking alcohol, discovered a profound sense of calm and purpose, and found that life became generally easier. This pivotal experience led to a lasting friendship and creative collaboration with Andrea.</p>
    
    <p>In 2020, during the Covid lockdown, Julia committed to deepening her understanding of hypnotherapy by embarking on a two-year training program and certification under Andrea’s guidance. Initially envisioning hypnosis as a tool for her art practice, Julia soon recognized its profound transformative power and felt compelled to integrate it into all aspects of her work.</p>
    
    <p>This passion gave birth to Spectral Center, an innovative practice that combines creativity, imagination, and the transformative potential of the subconscious mind. Julia’s offerings include 1:1 hypnotherapy sessions, group sessions, and digital self-hypnosis designed to make the power of hypnosis accessible, beautiful, and enjoyable.</p>
    
    <p>Driven by a deep curiosity about the subconscious and a desire to empower others to heal themselves, Julia also creates immersive experiences through t.r.a.n.c.e community hypnosis sessions with live music with Diana Duta, meditative lecture performances, and personalized sound prescriptions with the Audio Placebo Plaza collective, alongside Erin Gee and Vivian Li. She also co-hosts and produces the <em>A Kind of Harmony Podcast</em>, focused on the transformative potential of sound, with Amanda Harvey.</p>
    
    <p>Julia’s work has been showcased internationally at esteemed venues such as the Karachi Biennale (PK), Haus der Kulturen der Welt (DE), Cafe OTO (UK), Q-O2 (BE), Palais des Beaux-Arts de Paris (FR), and Musée d’art de Joliette (QC), among others.</p>
    </Drop>
</Body>
       <Body>
    <H1 onClick={() => toggleSection('hypnosis')}>What is Hypnosis?</H1>
    <Drop isOpen={openSections.hypnosis}>
    <p>Hypnosis is a powerful technique that facilitates changes in behaviors, thoughts, and emotions safely and effectively. It can be employed to achieve specific results, such as breaking habits like smoking or nail-biting, improving sleep, or managing stress. In the state of hypnosis, the subconscious mind becomes more receptive, allowing suggestions, metaphors, and visualizations to be deeply absorbed and integrated.</p>
    <p>The suggestions, visualizations, and metaphors you receive during hypnosis will vary based on the specific changes you wish to achieve in your life.</p>
    </Drop>
    </Body>
    <Body>
    <H1 onClick={() => toggleSection('services')}>Services</H1>
<Drop isOpen={openSections.services}>
    <h3>1:1 Hypnotherapy</h3>
    <p>Offered virtually or in person, 1:1 hypnotherapy is the most potent form of hypnosis, as sessions are tailored specifically to your intentions. Each session includes a consultation, and often involves dialogue between you and the therapist while the hypnosis is ongoing. This personalized approach ensures that the therapeutic process aligns closely with your unique needs and goals.</p>

    <h3>Group Hypnosis Sessions</h3>
    <p>Group hypnosis combines the practice of hypnotherapy with the synergy of voice, electronics, and resonant instruments. Unlike 1:1 therapy, group sessions focus on collective healing, addressing broader issues commonly experienced by many, such as self-doubt, low energy, pleasure, stress, and anxiety. These sessions benefit from the power of communal rest and shared experiences, fostering a supportive environment for transformation.</p>

    <h3>Self-Hypnosis</h3>
    <p>Self-hypnosis involves listening to pre-recorded sessions designed to guide you into a state of deep relaxation and heightened suggestibility. These tracks offer visualizations and suggestions tailored to your chosen goals, making it easier to achieve and maintain the desired trance state. Self-hypnosis is a convenient and effective way to access the benefits of hypnotherapy at your own pace and in your own space.</p>

    <p>Experience the transformative power of hypnosis through our personalized 1:1 sessions, collective group experiences, and accessible self-hypnosis products. Unlock the potential of your subconscious mind and achieve your desired changes with our expert guidance and support.</p>
    </Drop>
</Body>
    </Wrapper>
    </DeskWrap>
    <Footer/>
    </>
    );
    
    }


    const Gif = styled.img`
    max-width: 150px;
    position: absolute;
    margin-left: 200px;
    z-index: 1000;
    top: 5px;
    right: 5px;

    @media (min-width: 1000px) {
        max-width: 200px;
    position: absolute;
    z-index: 1000;
    top: 5px;
    right: 100px;
    }
    `
const DeskWrap = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;

    @media (min-width: 1000px) {
flex-direction: row;

}
`


const Wrapper=styled.div`
    display: flex;
    padding: 2%;
    flex-direction: column;
    justify-content: space-between;
    height: 90%;
    width: 90%;
    margin: 2%;

    @media (min-width: 500px) { }

    @media (min-width: 1000px) {

        justify-content: flex-start;
        height: 100%;
        padding: 0%;
        margin-right: 10%;
        width: 100%;


     }

    `
const SpectraDef = styled.div`
font-size: 14px;
`


const Body = styled.div`
   font-family: var(--font-body);
   margin-top: 3%;

   
   @media (min-width: 1000px) {

margin-top: 0%;


}
`

 const Drop = styled.div`
  max-height: ${({ isOpen }) => (isOpen ? '1000px' : '0')};
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  overflow: hidden;
  transition: max-height 0.6s ease, opacity 0.6s ease;
  margin-top: ${({ isOpen }) => (isOpen ? '10px' : '0')};
 `

const H1 = styled.h1`
font-size: 20px;
cursor: pointer;

&:hover {
    color: #ffc9eb;
  }
`
   const Img = styled.img`
   max-width: 100%;
   border-radius: 5%;


   @media (min-width: 500px) {
    max-width: 70%;
    margin-left: 15%;
    margin-right: 15%; 
   border-radius: 5%;

   }

@media (min-width: 1000px) { 
max-width: 500px;

}


   `
    export default About;