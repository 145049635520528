import React, {useState} from 'react';
import GumroadFollowForm from './GumroadFollowForm';
import styled from 'styled-components';
import Menu from './Menu';
import Footer from './Footer';
import Navbar from './Navbar';
import img1 from '../images/group1-min.png'
import img2 from '../images/group2-min.png'
import img3 from '../images/group3-min.png'

import DesktopMenu from './DesktopMenu';
import gif from '../images/gif3.gif'



const Group = () => {




    return (
        <>
<Navbar/>
<DeskWrap>
<DesktopMenu/>
<Gif src= {gif}/>

<Wrapper>

    <h1>Group Hypnosis</h1>

<Body>



    <p>Group hypnosis sessions invite participants to harness the power of the trance state and the potential of the subconscious mind to explore realms of pleasure and fulfillment.</p>
    
    <p>These sessions combine the practice of hypnotherapy with the synergy of voice, electronics, and resonant instruments. In this deeply relaxed state, a hypnotic narrative and live soundscape guide listeners on an odyssey through the theater of the mind, opening portals of possibility.
    </p>
    
    <p>Unlike 1:1 therapy, group hypnosis focuses on collective healing from the stresses and challenges of modern life, benefiting from the power of communal rest.
    </p>

    <Images>
<Img src= {img1}/>
<Img src= {img2}/>
<Img src= {img3}/>
</Images>
    
    <h1>Offerings include:</h1>
   
    <ol>
      <li><Strong>Group Sessions:</Strong>  Open to the public, in person and online,  fostering a sense of shared experience.</li>
      <li><Strong> <WebLink href= "https://www.tr4nc3.com/" target="blank"> t.r.a.n.c.e community hypnosis:  </WebLink> </Strong> Monthly community sessions for small groups in Brussels, with sound by Diana Duta.</li>
      <li><Strong>Team / Workplace  Sessions:</Strong>  Designed to reduce stress, boost productivity, and enhance overall well-being in professional environments.
</li>

</ol>

<h1>Upcoming Sessions:</h1>
   
   <ol>
     <li><Strong> 17.07.24 20h00<WebLink href= "https://calendly.com/4thspacemeditation/julia-e-dyck-group-hypnosis-session?back=1&month=2024-07" target="blank"> Group Hypnosis @ 4th Space Meditation Centre   </WebLink> </Strong> Brussels, BE</li>
     <li><Strong>29.07.24 20h00<WebLink href= "https://www.tr4nc3.com/" target="blank">  t.r.a.n.c.e community hypnosis  </WebLink> </Strong> Brussels, BE </li>

</ol>



</Body>
      
    </Wrapper>
    </DeskWrap>
    <Footer/>
    </>
    );
    
    }


    const Images = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 5%;

    @media (min-width: 500px) {
    max-width: 100%;
flex-direction: row;
justify-content: flex-start;
margin-bottom: 1%;


   }

@media (min-width: 1025px) { 
margin-top: 1%;

}
    
    `
    const Strong = styled.h3`
    font-weight: 600;
    `

    const Gif = styled.img`
    max-width: 150px;
    position: absolute;
    margin-left: 200px;
    z-index: 1000;
    top: 5px;
    right: 5px;

    @media (min-width: 1025px) {
        max-width: 200px;
        position: absolute;
        z-index: 1000;
        top: 500px;
        right: 200px;
    }
    `
const DeskWrap = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;

    @media (min-width: 1000px) {
flex-direction: row;

}
`


const Wrapper=styled.div`
    display: flex;
    padding: 2%;
    flex-direction: column;
    justify-content: space-between;
    height: 90%;
    width: 90%;
    margin: 2%;

    @media (min-width: 500px) { }

    @media (min-width: 1025px) {

        justify-content: flex-start;
        height: 100%;
        padding: 0%;
        margin-right: 10%;
        width: 100%;


     }

    `

const WebLink = styled.a`
color: black;
text-decoration: underline;`

const Body = styled.div`
   font-family: var(--font-body);
   margin-top: 3%;

   
   @media (min-width: 1035px) {

margin-top: 0%;


}
`


const H1 = styled.h1`
font-size: 20px;
cursor: pointer;

&:hover {
    color: #ffc9eb;
  }
`
   const Img = styled.img`
max-width: 80%;
    border-radius: 1rem;
    margin-top: 2%;


   @media (min-width: 500px) {
    max-width: 200px;
    margin-left: 1%;
    margin-right: 1%; 
   border-radius: 5%;

   }

@media (min-width: 1025px) { 
max-width: 300px;

}


   `
    export default Group;