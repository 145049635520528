import styled from "styled-components";
import React, { useEffect } from 'react';


const Optimism = ()=> {
    


  useEffect(() => {
    // Create script element
    const script = document.createElement('script');
    script.src = 'https://gumroad.com/js/gumroad-embed.js';
    script.async = true;

    // Append script to body
    document.body.appendChild(script);

    // Cleanup script when component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="gumroad-product-embed">
      <a href="https://spectralcenter.gumroad.com/l/optimism-hypnosis">Loading...</a>
    </div>
  );
};



export default Optimism;